import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import React from "react";
import c_hall_logo from "../../media/Asset8@2x.png";
import cosentino_logo from '../../media/cosentino-logo@2x.png';
import welcome_c_hall_en from "../../media/ChallPacoOpenly@2x.png";
import welcome_c_hall_es from "../../media/ChallPacoSharing@2x.png";
import welcome_c_hall from "../../media/ChallPacoSharing@2x.png";
import welcome_c_next_mobile from "../../media/Cnext-welcome-mobile@2x.png";
import languageJson from "../../common/language/language";
import Countdown from "react-countdown";
import "./Welcome.scss";
import { languages } from "../../common/constants/languages";
import happySharing_logo from '../../media/happy-sharing@2x.png';

export class Welcome extends React.Component<any, any> {
  timer: any;

  constructor(props: any) {
    super(props);
    this.state = { timeNow: new Date(Date.now()) };
    this.timer = null;
  }

  componentWillMount() {
     this.timer = setInterval(() => { this.setState({ timeNow: new Date(Date.now()) }) }, 1000);
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }
  render() {
    let language = this.props.match.params.lang;
    const isEnglish = language === languages.en;
    const welcomeLogo = isEnglish ? welcome_c_hall : welcome_c_hall_es;
    const buttonText =
      isEnglish
        ? languageJson.chall.welcome.button_en
        : languageJson.chall.welcome.button_es;
    const dateText = isEnglish ? "November 3rd - CANTORIA" : "3 de Noviembre - CANTORIA"
    const accessEventTime = new Date(`${process.env.REACT_APP_ACCESS_EVENT_DATE}`);
    const dateTime = new Date(`${process.env.REACT_APP_DATE}`);
    const eventOpened = true; //accessEventTime < this.state.timeNow && true;
    eventOpened && clearInterval(this.timer);
   

    return (
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <div className="welcome">
          <div className="logos">
            <div>
              <img className="logo__cosentino" src={cosentino_logo} alt="Cosentino Logo" />
            </div>
            <img className='logo__happysharing' src={happySharing_logo} alt="Happy Sharing" />
          </div>
          <div className="container">
            <div className="button">
              <div>
                <img className="logo__welcome" src={welcomeLogo} alt="Welcome Logo" />
              </div>
              <button
                className={`${eventOpened ? "" : "disabled"}`}
               // className={"disabled"}
                //</div> 
                onClick={eventOpened ?
                  () => this.goToStreaming(language) :
                   () => null}
                //onClick={() => null}
              >
                {buttonText}
              </button>
            </div>
            <div className="countdown">
              <div className="numbers">
                <Countdown date={dateTime} daysInHours={true} />
              </div>
              <div className="info_text">{dateText}</div>
            </div>
          </div>
        </div>
      </MsalAuthenticationTemplate>
    );
  }


  goToStreaming = (language: string) => {
    this.props.history.push(`/streaming/${language}`);
  };
}
