import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import React from 'react';
import { languages } from '../../common/constants/languages';
import language from '../../common/language/language';
import c_hall_logo from '../../media/CHallLogo@2x.png';
import cosentino_logo from '../../media/cosentino-logo@2x.png';
import happySharing_logo from '../../media/happy-sharing@2x.png';
import './Language.scss';

export class Language extends React.Component<any, any> {
  render() {
    return (
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}>
        <div className='language'>
          <div className='container'>
          <img className='logo__c-hall' src={c_hall_logo} alt="C-Hall Logo" />
          <div className='text-container'>
            <div>{language.chall.selectLanguage.en}</div>
            <div>{language.chall.selectLanguage.es}</div>
          </div>
          <div className='button-container'>
            <button onClick={() => this.goToWelcome(languages.es)}>ESP</button>
            <button onClick={() => this.goToWelcome(languages.en)}>ENG</button>
           </div>
          </div>
          <img className='logo__cosentino' src={cosentino_logo} alt="Cosentino Logo" />
          <img className='logo__happysharing' src={happySharing_logo} alt="Happy Sharing" />
        </div>
      </MsalAuthenticationTemplate>
    );
  }

  goToWelcome = async (language: string) => {
    this.props.history.push(`/welcome/${language}`);
  }
}